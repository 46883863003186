import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';
import axios from 'axios';
import moment from 'moment-jalaali';
import { Login } from '../../helpers/APIHandlers';
import LoginForm from '../../components/forms/login/LoginForm';
import { SET_CURRENT_DATE, SET_CURRENT_CURRENCY } from '../../components/context/reducer/actionTypes';
import { DashboardPanelContext } from '../../components/context/DashboardContext';
import { BSwalShowError } from '../../common/BSwal';
import { UserCredentialsContext } from '../../components/context/LoginContext';
import { forms } from '../../data/Dictionary';
import Locale from '../../helpers/Locale';

export default function Index() {
  const Language = Locale();
  const { globalDispatch } = useContext(DashboardPanelContext);
  const { onChangeCredentials } = useContext(UserCredentialsContext);
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const cookies = new Cookies();

  const loginCredentials = async (inputData) => {
    setLoading(true);
    Login(inputData.email, inputData.password)
      .then(async ({ data }) => {
        cookies.set('userToken', data?.authorisation.token, {
          maxAge: new Date().getTime() + 1000 * 36000,
          path: '/',
        });
        await onChangeCredentials(data);
        globalDispatch({
          type: SET_CURRENT_CURRENCY,
          value: {
            id: 'irt',
            name: 'تومان',
          },
        });
        // localStorage.setItem('userCredentials', JSON.stringify(data));
        axios.defaults.headers.Authorization = `Bearer ${data?.authorisation.token}`;
      })
      .catch((error) => {
        if (error?.data?.messages) BSwalShowError(error?.data?.messages);
        else if (!error) BSwalShowError(['Error in connection with server.']);
        else BSwalShowError([forms[Language].loginIncorrect]);
      })
      .finally(() => setLoading(false));
  };

  if (cookies.get('userToken')) {
    const currentDate = moment().format('MM-DD-YYYY');
    globalDispatch({ type: SET_CURRENT_DATE, value: currentDate });
    navigate('/');
  }
  return <LoginForm loginCredentials={loginCredentials} loading={loading} />;
}
